<template>
  <v-menu
    v-model="menu"
    transition="scale-transition"
    max-width="290px"
    min-width="auto"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex">
        <v-text-field-simplemask
          v-model="fecha"
          :label="label"
          v-bind:properties="{
            placeholder: 'dd/mm/aaaa',
            clearable: clearable,
            'hide-details': hideDetails,
            readonly: disabled,
            filled: disabled,
            outlined: true,
            dense: true
          }"
          v-bind:options="{
            inputMask: '##/##/####',
            outputMask: '##/##/####',
            empty: null
          }"
          @blur="disabled ? '' : validar_fecha()"
          @change="$emit('change')"
        />
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="ml-1"
          style="margin-top: -2px;"
          :color="disabled ? '' : 'info'"
          :title="disabled ? '' : 'Seleccionar fecha'"
          :disabled="disabled"
          icon
          @click="date = parseDate(fecha)"
        >
          <v-icon>fas fa-calendar-day</v-icon>
        </v-btn>
      </div>
    </template>
    <v-date-picker
      v-model="date"
      :key="key"
      :min="parseDate(fecha_minima)"
      :max="parseDate(fecha_maxima)"
      no-title
      @input="menu = false"
      @change="$emit('change')"
    ></v-date-picker>
  </v-menu>
</template>

<script>
/**
 * Componente para seleccionar una fecha. Devuelve la fecha en formato DD/MM/AAAA
 */
import moment from 'moment'

export default {
  data () {
    return {
      key: 0,
      menu: false,
      date: null
    }
  },
  props: {
    value: String, // obligatorio, peude enviarse null o con una fecha predefinida
    label: {
      type: String,
      default: ''
    },
    fecha_minima: { // fecha mínima seleccionable en formato dd/mm/aaaa
      type: String,
      default: null
    },
    fecha_maxima: { // fecha máxima seleccionable en formato dd/mm/aaaa
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    date (val) {
      this.fecha = this.formatDate(val)
    }
  },
  computed: {
    fecha: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    validar_fecha () {
      // si la fecha es vacia borro el date
      if (!this.fecha) {
        this.fecha = null
        this.date = null
        this.key ++
      }
      // sino valido la fecha ingresada
      else if (moment(this.parseDate(this.fecha)).isValid() && this.fecha.length == 10) {
        // si la fecha es valida debo revisar que sea mayor a la fecha minima (en caso de existir)
        if (moment(this.parseDate(this.fecha)).isBefore(this.parseDate(this.fecha_minima)) ) {
          this.fecha = null
          this.date = null
          this.key ++
          return this.$store.dispatch('show_snackbar', {
            text: 'La fecha no puede ser menor que ' + this.fecha_minima,
            color: 'orange'
          })
        }
        // si la fecha es valida debo revisar que sea menor a la fecha maxima (en caso de existir)
        if (moment(this.parseDate(this.fecha_maxima)).isBefore(this.parseDate(this.fecha)) ) {
          this.fecha = null
          this.date = null
          this.key ++
          return this.$store.dispatch('show_snackbar', {
            text: 'La fecha no puede ser mayor que ' + this.fecha_maxima,
            color: 'orange'
          })
        }
        // si paso todas las validaciones puedo hacer que date sea igual a la fecha
        this.date = this.parseDate(this.fecha)
      }
      else {
        this.fecha = null
        this.date = null
        this.key ++
        this.$store.dispatch('show_snackbar', {
          text: 'Fecha inválida',
          color: 'orange'
        })
      }
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    }
  }
}
</script>